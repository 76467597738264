<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h3>Magazyn</h3>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="form-group">
          <label for="title">Nazwa przedmiotu: </label>
          <input
            id="title"
            v-model="Item.name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <Editor
            v-model="Item.description"
            api-key="b8rjrbaxn62c6iwmxh1tg94bjzp309azf3n243jxljv1ndwn"
            :toolbar="toolbar"
            style="height: 400px;"
          />
        </div>
        <div class="form-group">
          <label for="title">ilość przedmiotu: </label>
          <input
            id="title"
            v-model="Item.quantity"
            type="number"
            class="form-control"
          >
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h3>Zdjęcie</h3>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="form-group">
          <label for="title">Dodaj zdjęcie:</label>
          <!-- Styled -->
          <b-form-file
            v-model="Item.image"
            :state="Boolean(Item.image)"
            placeholder="Wybierz zdjęcie lub przeciągnij"
            drop-placeholder="Przeciągnij zdjęcie tutaj"
          />
        </div>
        <div class="form-group">
          <img
            :src="image"
            alt=""
          >
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <button
        class="btn btn-primary"
        style="width:100%;"
        @click="AddItem ()"
      >
        Zapisz produkt w magazynie
      </button>
    </b-card>
  </div>
</template>

<script>
import {
  BCalendar, BCalendarDay, BCard, BForm, BFormFile,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  name: 'Calendar',
  components: {
    BCalendar,
    BCalendarDay,
    BCard,
    BForm,
    Editor,
    BFormFile,
  },
  data() {
    return {
      Item: {
        description: '',
        count: 0,
      },
    }
  },
  mounted() {
    document.title = 'Magazyn - Pickmode'
  },
  methods: {
    AddItem() {
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const formData = new FormData()
      formData.append('name', this.Item.name)
      formData.append('description', this.Item.description)
      formData.append('quantity', this.Item.quantity)
      formData.append('image', this.Item.image)
      axios.post(`${process.env.VUE_APP_API_URL}warehouse`, formData, config)
        .then(response => {
          swal.fire({
            title: 'Zapisano',
            icon: 'success',
            text: 'Zapisano produkt w magazynie',
            type: 'success',
            confirmButtonText: 'OK',
          })
          // push router
          this.$router.push('/magazyn')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style>

</style>
